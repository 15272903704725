<template>
  <div>
    <div class="retailers" v-if="country === 'gb'">
      <div v-for="(retailer, index) in RetailersGB" :key="index">
        <a :href="retailer.link" target="_blank">
          <div class="retailer">
            <img  v-lazy="`$/images/cilp-retailers/${retailer.imgSrc}`" :alt="retailer.alt">
          </div>
        </a>
      </div>
    </div>
    <div class="retailers" v-if="country === 'de'">
      <div v-for="(retailer, index) in RetailersDE" :key="index">
        <a :href="retailer.link" target="_blank">
          <div class="retailer">
            <img  v-lazy="`$/images/cilp-retailers/${retailer.imgSrc}`" :alt="retailer.alt">
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Locator from '@/core/services/Locator'

export default {
  data () {
    return {
      country: 'gb',
      RetailersGB: [
        { link: 'https://www.awin1.com/cread.php?awinmid=273&awinaffid=272501&clickref=A2ASUBID', imgSrc: 'Buy_A_Gift.png', alt: 'BuyaGift' },
        { link: 'https://www.awin1.com/cread.php?awinmid=10953&awinaffid=272501&clickref=A2ASUBID&ued=', imgSrc: 'Pooch&Mutt.png', alt: 'Pooch and Mutt' },
        { link: 'https://www.awin1.com/cread.php?awinmid=5578&awinaffid=272501&clickref=A2ASUBID', imgSrc: 'GoGroopie.png', alt: 'Go Groopie' },
        { link: 'https://click.linksynergy.com/deeplink?id=jc5PrZcv/2Y&mid=39654&u1=A2ASUBID&murl=https%3A%2F%2Fwww.harveynichols.com%2F', imgSrc: 'Harvey_Nichols.png', alt: 'Harvey Nichols' },
        { link: 'https://www.awin1.com/cread.php?awinmid=1983&awinaffid=272501&clickref=A2ASUBID', imgSrc: 'Red_Letter_Days.png', alt: 'Red Letter Days' },
        { link: 'http://t.groupon.co.uk/r?tsToken=UK_AFF_0_211549_1699299_0&sid=A2ASUBID&url=https%3A%2F%2Fwww.groupon.co.uk%2F', imgSrc: 'Groupon.png', alt: 'Groupon' },
        { link: 'https://click.linksynergy.com/deeplink?id=jc5PrZcv/2Y&mid=44523&u1=A2ASUBID&murl=https%3A%2F%2Fwww.superdry.com%2F', imgSrc: 'Superdry.png', alt: 'Superdry' },
        { link: 'https://click.linksynergy.com/fs-bin/click?id=jc5PrZcv/2Y&offerid=355235.10000635&type=3&subid=0&u1=A2ASUBID', imgSrc: 'Charles_Tyrwhitt.png', alt: 'Charles Tyrwhitt' },
        { link: 'https://click.linksynergy.com/deeplink?id=jc5PrZcv/2Y&mid=44235&u1=A2ASUBID&murl=https%3A%2F%2Fwww.feelunique.com%2F', imgSrc: 'Feel_Unique.png', alt: 'Feel Unique' },
        { link: 'https://amzn.to/3jzBiU3', imgSrc: 'Amazon.png', alt: 'Amazon' },
        { link: 'http://rover.ebay.com/rover/1/710-53481-19255-0/1?ff3=4&pub=5575574705&toolid=10001&campid=5338662692&customid=A2ASUBID&mpre=https%3A%2F%2Fwww.ebay.co.uk%2F', imgSrc: 'Ebay.png', alt: 'Ebay' },
        { link: 'https://www.awin1.com/cread.php?awinmid=2319&awinaffid=272501&clickref=A2ASUBID', imgSrc: 'Boohoo.png', alt: 'BooHoo' },
        { link: 'https://nordvpn.sjv.io/c/417474/493823/7452?subId1=A2ASUBID', imgSrc: 'NordVPN.png', alt: 'NordVPN' },
        { link: 'https://www.awin1.com/cread.php?awinmid=3196&awinaffid=272501&clickref=A2ASUBID', imgSrc: 'My_Protein.png', alt: 'My protein' },
        { link: 'https://www.anrdoezrs.net/links/8242621/type/dlg/sid/A2ASUBID/https://www.sainsburys.co.uk', imgSrc: 'Sainsburys.png', alt: 'Sainsburys' },
        { link: 'https://www.asos.com/', imgSrc: 'Asos.png', alt: 'Asos' }
      ],
      RetailersDE: [
        { link: 'https://www.awin1.com/cread.php?awinmid=14598&awinaffid=333895&clickref=A2ASUBID', imgSrc: 'Flaconi.png', alt: 'Flaconi' },
        { link: 'https://www.gamestop.de/', imgSrc: 'GameStop.png', alt: 'GameStop' },
        { link: 'https://na-kd.com/', imgSrc: 'NAKD.png', alt: 'NA-KD' },
        { link: 'https://www.awin1.com/cread.php?awinmid=14865&awinaffid=333895&clickref=A2ASUBID', imgSrc: 'Galeria.png', alt: 'Galeria.de' },
        { link: 'http://www.mediamarkt.de/', imgSrc: 'MediaMarkt.png', alt: 'Media Markt' },
        { link: 'https://cdn.retailads.net/tc.php?t=150989C2157130943T&subid=A2ASUBID&deeplink=https%3A%2F%2Fwww.intersport.de%2F', imgSrc: 'InterSport.png', alt: 'Intersport' },
        { link: 'https://www.awin1.com/cread.php?awinmid=10700&awinaffid=333895&clickref=A2ASUBID', imgSrc: 'My_Protein.png', alt: 'MyProtein' },
        { link: 'https://www.awin1.com/cread.php?awinmid=10073&awinaffid=333895&clickref=A2ASUBID', imgSrc: 'jds.png', alt: 'JDSports' },
        { link: 'http://www.cyberport.de/', imgSrc: 'Cyberport.png', alt: 'Cyberport' },
        { link: 'https://www.awin1.com/cread.php?awinmid=14815&awinaffid=737891&clickref=A2ASUBID', imgSrc: 'Samsung.png', alt: 'Samsung' },
        { link: 'https://joinpouch-de.digidip.net/visit?url=http%3A%2F%2Fwww.westwing.de&ref=A2ASUBID', imgSrc: 'Westwing.png', alt: 'Westwing' },
        { link: 'https://www.awin1.com/cread.php?awinmid=14757&awinaffid=333895&clickref=A2ASUBID', imgSrc: 'Fressnapf.png', alt: 'Fressnapf' },
        { link: 'https://www.awin1.com/awclick.php?gid=347465&mid=11786&awinaffid=333895&linkid=2334116&clickref=A2ASUBID', imgSrc: 'Christ.png', alt: 'Christ' },
        { link: 'https://www.awin1.com/cread.php?awinmid=11354&awinaffid=333895&clickref=A2ASUBID', imgSrc: 'Conrad.png', alt: 'Conrad' },
        { link: 'http://misterspex.de/', imgSrc: 'MisterSpex.png', alt: 'Mister Spex' },
        { link: 'https://www.awin1.com/cread.php?awinmid=18030&awinaffid=737891&clickref=A2ASUBID', imgSrc: 'Groupon.png', alt: 'Groupon' }
      ]
    }
  },

  beforeMount () {
    this.country = Locator.country
  }
}
</script>

<style lang="sass" scoped>
.retailers
  display: flex
  flex-flow: row wrap
  align-items: center
  justify-content: center
  .retailer
    display: flex
    align-items: center
    justify-content: center
    margin: 11.75px 11.75px
    width: 253.2px
    height: 193.9px
    border-radius: 6.7px
    background-color: white
    box-shadow: 0 0 2px 0 rgba(84, 84, 84, 0.15)
    &:hover
      box-shadow: 0 0 4px 0 rgba(61, 61, 61, 0.8)
    img
      width: 100%
      height: 98%
      border-radius: 100%
</style>
