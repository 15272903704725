<template>
  <div class="cards">
    <div class="card" @click="flipCard('card1')">
      <div :class="{ flip: card1 }" class="card-inner">
        <div class="card-front">
          <div class="card-image">
            <img
              v-lazy="`$/images/never_overpay_cilp.svg`"
              :alt="$t('home.cards.never-overpay-label')"
              :class="card1 ? 'fade-leave-active': 'fade-enter-active'"
            >
          </div>
          <div class="card-description">
            <span v-t="`cilp.cards.save-up`"></span>
            <b class="has-text-primary" v-t="`cilp.cards.300`"></b>
          </div>
        </div>
        <div class="card-back">
          <span v-t="`cilp.cards.our-mission`"></span>
        </div>
      </div>
    </div>

    <div class="card" @click="flipCard('card2')">
      <div :class="{ flip: card2 }" class="card-inner">
        <div class="card-front">
          <div class="card-image">
            <img
              v-lazy="`$/images/data_protection_cilp.svg`"
              :alt="$t('home.cards.data-protection-label')"
              :class="card2 ? 'fade-leave-active': 'fade-enter-active'"
            >
          </div>
          <div class="card-description">
              <span v-t="`cilp.cards.your-data`"></span>
              <b class="has-text-primary" v-t="`cilp.cards.safe`"></b>
          </div>
        </div>
        <div class="card-back">
          <i18n path="cilp.cards.data.protection" tag="span">
            <template #link>
              <a class="link has-text-primary"
                href="https://joinpouch.com/en-gb/blog/how-pouch-looks-after-your-data"
                target="_blank"
                v-t="`cilp.cards.data.link`"
              ></a>
            </template>
          </i18n>
        </div>
      </div>
    </div>

    <div class="card" @click="flipCard('card3')">
      <div :class="{ flip: card3 }" class="card-inner">
        <div class="card-front">
          <div class="card-image">
            <img
              v-lazy="`$/images/its_free_cilp.svg`"
              :alt="$t('home.cards.its-free-label')"
              :class="card3 ? 'fade-leave-active': 'fade-enter-active'"
            >
          </div>
          <div class="card-description">
              <span v-t="`cilp.cards.its`"></span>
              <b class="has-text-primary" v-t="`cilp.cards.free`"></b>
          </div>
        </div>
        <div class="card-back">
          <span v-t="`cilp.cards.how-it-works`"></span>
        </div>
      </div>
    </div>

    <div class="card" @click="flipCard('card4')">
      <div :class="{ flip: card4 }" class="card-inner">
        <div class="card-front">
          <span class="card-badge has-text-primary has-text-weight-light" :class="card4 ? 'fade-leave-active' : 'fade-enter-active'">NEW!</span>
          <div class="card-image">
            <img
              v-lazy="`$/images/favourite-brands.svg`"
              alt="Never miss a deal from your Favourite Brands!"
              :class="card4 ? 'fade-leave-active': 'fade-enter-active'"
            />
          </div>
          <div class="card-description">
              <span class="has-text-weight-normal">Never miss a deal from</span>
              <span class="has-text-weight-normal">your <b class="has-text-primary has-text-weight-semibold">Favourite Brands!</b></span>
          </div>
        </div>
        <div class="card-back">
          <span>
              We instantly notify you whenever one of your <a target="_blank" href="https://www.deals.joinpouch.com/post/favourite-brands-is-now-live-never-miss-another-deal-you-want ">Favourite Brands</a> has a new offer. Discover and add your favourite brands now.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      card1: false,
      card2: false,
      card3: false,
      card4: false
    }
  },

  methods: {
    flipCard (card) {
      this.$data[card] = !this.$data[card]
    }
  }
}
</script>

<style lang="sass" scoped>
.cards
  display: flex
  flex-flow: row wrap
  align-items: center
  justify-content: center
  margin-top: 50px
  z-index: 10
  .card
    margin: 11.75px 11.75px
    width: 253.1px
    height: 265.8px
    background-color: transparent
    box-shadow: none
    cursor: pointer
    perspective: 1000px
    .flip
      transform: rotateY(-180deg)
    .flop
      transform: rotateY(180deg)
    .card-inner
      width: 253.1px
      height: 265.8px
      transition: transform 0.8s
      border-radius: 6.7px
      box-shadow: 0 0 4px 0 rgba(61, 61, 61, 0.2)
      transform-style: preserve-3d
      &:hover
        box-shadow: 0 0 4px 0 rgba(61, 61, 61, 0.8)
      .card-front
        display: flex
        align-items: center
        justify-content: center
        flex-direction: column
        border-radius: 6.7px
        background-color: white
        position: relative
        .card-image
          width: 103.4px
          height: 100.8px
          img
            width: 90px
            height: auto
        .card-description
          display: flex
          flex-direction: column
          margin-top: 18px
          font-size: 19.5px
          font-weight: 500
        .card-badge
          position: absolute
          top: 30px
          right: 30px
      .card-back
        display: flex
        justify-content: center
        align-items: center
        border-radius: 6.7px
        background-color: white
        padding: 0 30px
        font-size: 15px
        font-weight: 300
        transform: rotateY(180deg)
        .link
          &:hover
            text-decoration: underline
      .card-front, .card-back
        position: absolute
        -webkit-backface-visibility: hidden /* Safari */
        backface-visibility: hidden
        width: 100%
        height: 100%
</style>
