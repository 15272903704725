<template>
  <div class="container is-fluid">
    <div class="columns is-centered">
      <div class="column is-3-desktop is-6-tablet">
        <carousel
          :loop="true"
          :per-page="1"
          :pagination-padding="5"
          :navigateTo="activeTestimonial"
          :paginationActiveColor="$config.style.primaryColor"
        >
          <slide v-for="(testimonial, key) in testimonials" :key="key">
            <blockquote
              @click="$store.dispatch('activateTestimonial', key)"
              class="box testimonial has-padding-3 content has-text-centered"
            >
              <div>
                <p class="title is-5">{{ testimonial.name }}</p>
                <p class="subtitle is-7">{{ testimonial.source.name }}</p>
                <p class="has-text-warning">
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                </p>
              </div>

              <p class="has-text-grey">{{ testimonial.body }}</p>
              <p><i class="fas fa-quote-right"></i></p>
            </blockquote>
          </slide>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { Carousel, Slide } from 'vue-carousel'

export default {
  components: { Carousel, Slide },

  computed: {
    ...mapState({
      testimonials: state => state.testimonials
    }),

    ...mapGetters({
      activeTestimonial: 'activeTestimonial'
    })
  }
}
</script>
