<template>
  <main-layout>
    <section v-if="currentMerchant" class="section-retailer-intro is-flex">
      <div class="column is-5 retailer-intro-images" style="position: relative;">
        <img v-lazy="`$/images/crlp_banner.png`" alt="Generic Pouch Banner" style="width: 100%; height: auto;">
        <div style="position: absolute; top: 0; left: 0; padding-top: calc(10% + 4px); padding-left: 8%; width: 100%">
          <img v-lazy="currentMerchant.logo" style="width: 15%">
          <img v-lazy="`$/images/crlp_basket.png`" alt="" style="margin-top: 2%; width: 45%; display: block;">
        </div>
        <img v-lazy="currentMerchant.logo" style="position: absolute; top: 0px; left: 62%; padding-top: calc(14% + 10px); max-width: 4%; opacity: 0.8;">
      </div>

      <div class="column is-5-fullhd is-offset-1 content has-text-centered-mobile hero-title">
        <h1>
          <span
            v-t="`home.relax`"
            class="category-title has-text-weight-black"
          ></span>
          <i18n
              path="home.introduction"
              tag="div"
              class="category-subtitle"
              style="font-weight: 300"
            >
              <template #merchant-name>
                <span style="color:green; font-weight: 700;">{{ currentMerchant.name }}</span>
              </template>
            </i18n>
        </h1>

        <p v-only-extension-fit class="has-text-grey is-size-4" v-t="`home.extension-description`">
        </p>

        <p v-only-extension-fit>
          <add-to-browser
            medium
            bold
            position="CustomerRetailerLandingPage"
          >
            <span v-t="`buttons.add-pouch`"></span>
          </add-to-browser>
        </p>

        <br v-only-with-extension />

        <div v-only-extension-fit>
          <div class="rating-container">
            <rating class="rating" size='6' />
            <span>
              <span v-t="`cilp.trusted`"></span>
              <b v-t="`cilp.shoppers`"></b>
            </span>
          </div>
        </div>
      </div>
    </section>

    <section v-else class="section-intro">

      <div class="container-laptop">
        <img
          v-if="this.influencer.campaign === 'category'"
          v-lazy="profileSrc"
          class="laptop-category"
          alt="Open laptop image"
        >
        <img
          v-if="this.influencer.campaign !== 'category'"
          class="laptop"
          v-lazy="backgroundSrc"
          alt="Open laptop image"
        >
        <img
          v-if="this.influencer.campaign !== 'category'"
          class="influencer-profile"
          :src="profileSrc"
          alt="Influencer profile image"
        >
      </div>

      <div class="col">
        <h1
          v-if="this.influencer.campaign !== 'category'"
          v-t="`partials.navbar.nav-links.user.greeting`"
        ></h1>

        <h1
          v-else
          v-t="`home.relax`"
          class="category-title"
        ></h1>

        <div class="subtitle">
          <p
            :class="this.influencer.campaign !== 'category' ? 'influencer-subtitle' : 'category-subtitle'"
          >
            <span>{{ this.influencer.content[0] }}</span>
          </p>
        </div>

        <p class="description space">
          <span>{{ this.influencer.content[1] }}</span>
        </p>

        <div v-hidden-extension-fit class="unsupported-browser">
          <b v-t="`cilp.select`"></b>
          <p class="description space" v-t="`cilp.available`"></p>

          <div class="link-saver">
            <div
              v-if="!isLinkCopied"
              class="buttons-container"
            >
              <div class="browser-link-button" @click="copyHandler('chrome')">
                <img v-lazy="`$/images/chrome.svg`" alt="Chrome logo">
                <div>Chrome</div>
              </div>

              <div class="browser-link-button" @click="copyHandler('edge')">
                <img v-lazy="`$/images/edge.svg`" alt="Edge logo">
                <div>Edge</div>
              </div>

              <div class="browser-link-button" @click="copyHandler('firefox')">
                <img v-lazy="`$/images/firefox.svg`" alt="Firefox logo">
                <div>Firefox</div>
              </div>

            </div>

            <div
              v-if="clipboardSupport && isLinkCopied"
              class="special-message"
            >
              <div class="link-copied">
                <b class="green" v-t="`cilp.copied`"></b>
                <div v-t="`cilp.install`"></div>
              </div>

              <div class="back-button" @click="changeStatusIsLinkCopied()">
                <b v-t="`cilp.back`"></b>
              </div>
            </div>

            <div
              v-if="!clipboardSupport && isLinkCopied"
              class="special-message"
            >
              <div class="link-copied">
                <input class="input-link" :value="this.storeLink"/>
              </div>

              <div class="back-button" @click="changeStatusIsLinkCopied()">
                <b v-t="`cilp.back`"></b>
              </div>
            </div>
          </div>
        </div>

        <add-to-browser
          v-only-extension-fit
          class="special-button"
          :enableInstallPopup="false"
          position="InfluencerPage"
        >
          <i18n path="cilp.add-to-browser.add" tag="span">
            <template #browser>
              <span class="is-capitalized">
                {{ printBrowser() }}
              </span>
            </template>
          </i18n>
        </add-to-browser>

        <br v-only-with-extension />

        <div class="rating-container">
          <rating size='6' />
          <span>
            <span v-t="`cilp.trusted`"></span>
            <b v-t="`cilp.shoppers`"></b>
          </span>
        </div>
      </div>
    </section>

    <section class="section top-curve has-text-centered">
      <cards />
    </section>

    <section class="section special-bg has-text-centered">
      <div class="retailers-intro">
        <p class="special-p" v-t="`partials.footer.retailers`"></p>
        <h3 v-t="`cilp.stores`"></h3>
        <p v-t="`cilp.we-negotiate`"></p>
      </div>
    </section>

    <section class="section special-bg has-text-centered">
      <retailers />
    </section>

    <section class="section special-bg we-reward-container">
      <div class="retailers-intro we-reward">
        <p class="special-p" v-t="`home.points`"></p>
        <br/>
        <h3 v-t="`home.reward`"></h3>
        <p>
          <span v-t="`home.join-family`"></span>
          <b v-t="`home.earn`"></b>
          <span v-t="`home.by-shopping`"></span>
        </p>
        <p>
          <b v-t="`home.redeem`"></b>
          <span v-t="`home.from`"></span>
        </p>
        <div>
          <router-link :to="{ name: 'tandcs' }">
            T&amp;Cs
          </router-link>
          <span v-t="`home.apply`"></span>
        </div>
        <br />
        <br />
        <div class="retailers-intro__actions">
          <utm-itm-link class="is-primary button has-text-weight-semibold" :targetUrl="accountJoinUrl">
            Join &amp; Earn
          </utm-itm-link>
          <router-link :to="{ name: 'points' }" class="button has-text-weight-semibold is-text retailers-intro__leran-more" v-t="`myaccount.pouch-points.learn-more`">
          </router-link>
        </div>
      </div>

      <div class="amazon-vouchers">
        <img v-lazy="`$/images/giftcards.png`" alt="Amazon gift cards falling">
      </div>
    </section>

    <section class="section special-bg has-text-centered">
      <div class="retailers-intro">
        <p class="special-p" v-t="`home.exclusive`"></p>
        <h3>
          <span v-t="`deals.ads.facebook.main`"></span>
          <span v-t="`cilp.best-discounts`"></span>
        </h3>
        <p class="stop-overpaying">
          <span v-t="`cilp.stop`"></span>
          <span v-t="`cilp.best-price`"></span>
        </p>
      </div>
    </section>

    <section id="testimonials" class="section-testimonials bottom-curve">
      <testimonials></testimonials>
    </section>

    <section class="section-relax has-text-centered">
      <h1
        v-only-extension-fit
        class="has-text-primary"
        v-t="`home.relax`"
      ></h1>
      <h2
        v-only-extension-fit
        v-t="`home.what-we-do`"
      ></h2>
      <add-to-browser
        v-only-extension-fit
        class="special-button"
        :enableInstallPopup="false"
      >
        <i18n path="cilp.add-to-browser.add" tag="span">
          <template #browser>
            <span class="is-capitalized">
              {{ printBrowser() }}
            </span>
          </template>
        </i18n>
      </add-to-browser>
    </section>
  </main-layout>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Cards from './components/Cards'
import Rating from '@/views/components/Rating'
import Retailers from './components/Retailers'
import AddToBrowser from '@/views/partials/AddToBrowser'
import Testimonials from '@/views/components/Testimonials'
import UtmItmLink from '@/views/partials/UtmItmLink.vue'

export default {
  components: { Testimonials, AddToBrowser, Rating, Cards, Retailers, UtmItmLink },

  data () {
    return {
      storeLink: '',
      isLinkCopied: false,
      clipboardSupport: false,
      receiveInfoTextActive: false,
      receiveNewsletterTextActive: false,
      consentInfoActive: false,
      accountJoinUrl: this.$config.accountJoinUrl
    }
  },

  beforeCreate () {
    // Reinforce redirect if mobile device.
    if (crossDeviceSupport.mobileOs()) {
      location.href = '/'
      return new Promise(() => {})
    }
  },

  computed: {
    ...mapGetters({
      activeTestimonial: 'activeTestimonial',
      featuredPublications: 'featuredPublications'
    }),

    redirect () {
      return crossDeviceSupport.mobileOs() ? '/desktop-only' : '/unsupported-browser'
    },

    ...mapState({
      influencer: state => state.influencer,
      currentMerchant: state => state.crlp.currentMerchant,
      testimonials: state => state.testimonials,
      featuredRetailers: state => state.featuredRetailers
    }),

    isComp () {
      return ['fortnite', 'warzone'].includes(this.influencer.name)
    },

    backgroundSrc () {
      return `$/images/${
        this.isComp ? ('comp-whole-laptop-' + this.influencer.name) : 'cilp-whole-laptop'
      }.svg`
    },

    profileSrc () {
      return `https://influencer-profile-assets.s3.amazonaws.com/${this.influencer.name}.png`
    }
  },

  mounted () {
    // Evaluates the support of the Clipboard API.
    this.clipboardSupport = !!navigator.clipboard
  },

  methods: {
    copyHandler (value) {
      this.changeStatusIsLinkCopied()
      /**
       * Utms expected utm_source, utm_medium, utm_content, utm_campaign.
       */
      const utms = location.search
      this.storeLink = this.$config.extension.installationLinks[value] + utms

      if (this.clipboardSupport) {
        navigator.clipboard.writeText(this.storeLink)
      }
    },

    changeStatusIsLinkCopied () {
      return this.isLinkCopied = !this.isLinkCopied
    },

    printBrowser () {
      if (this.$browser.name.match('edge')) {
        return 'edge'
      }

      return this.$browser.name
    },

    formatName () {
      const rawName = this.influencer.name.replace(new RegExp('_', 'g'), ' ')
      const influencerName = rawName.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
      return influencerName
    }
  }
}
</script>

<style lang="sass" scoped>
.amazon-vouchers, .retailer-intro-images
  @media screen and ( max-width: 1240px )
      display: none
.section-retailer-intro
  display: flex
  align-items: center
  justify-content: center
  flex-flow: wrap
  padding: 70px 50px
  height:  auto
  background-image: url('/static/images/cilp-background.svg')
  background-repeat: no-repeat
  background-position-y: -230px
  background-size: 1400px
  font-size: 1.5rem
.section-intro
  display: flex
  align-items: center
  justify-content: center
  flex-flow: wrap
  padding: 150px
  height:  auto
  background-image: url('/static/images/cilp-background.svg')
  background-repeat: no-repeat
  background-position-y: -230px
  background-size: 1400px
  font-size: 1.5rem
  .col
    display: flex
    flex-direction: column
    align-items: left
    margin-left: 700px
    @media screen and ( max-width: 1240px )
      margin-left: 0px
  .container-laptop
    position: absolute
    top: 160px
    left: -280px
    .laptop
      width: 940px
    .laptop-category
      width: 890px
      margin-top: -50px
      margin-left: 80px
    .influencer-profile
      position: absolute
      top: 70px
      left: 570px
      width: 346px
    @media screen and ( max-width: 1240px )
      display: none
  h1
    height: 65px
    font-size: 53.6px
    font-weight: bold
    font-stretch: normal
    font-style: normal
    line-height: 0.93
    letter-spacing: normal
    text-align: left
    color: #363636
    .category-title
      font-size: 62px
      font-weight: 800
  .subtitle
    height: 95px
    flex-wrap: wrap
    .influencer-subtitle
      min-width: 380px
    .category-subtitle
      min-width: 600px
      max-width: 700px
      font-size: 62px
      font-weight: 300
    p
      font-size: 40px
      font-weight: 600
      font-stretch: normal
      font-style: normal
      line-height: 0.98
      letter-spacing: normal
      text-align: left
      color: #363636
  .description
    height: 47px
    font-family: Gilroy
    font-size: 20px
    font-weight: 300
    font-stretch: normal
    font-style: normal
    line-height: 1.2
    letter-spacing: normal
    text-align: left
    color: #363636
  .space
    width: 494px
    margin-top: 1rem
  .podcast-space
    width: 500px
  .unsupported-browser
    margin: 40px 0 20px
    font-size: 20px
    .link-saver
      display: flex
      justify-content: center
      align-items: center
      width: 479.4px
      min-height: 84px
      border-radius: 6.7px
      background-color: white
      .buttons-container
        display: flex
        .browser-link-button
          display: flex
          justify-content: center
          align-items: center
          width: auto
          min-height: 41px
          margin: 8px
          padding: 10px
          border: 1px solid rgba(244, 244, 244, 1)
          border-radius: 6.7px
          cursor: pointer
          &:hover
            border: 1.6px solid rgba(255, 100, 65, .5)
          div
            margin-left: 7px
            text-transform: uppercase
            font-size: 13.8px
            font-weight: 600
          img
            width: 32px
            height: 32px
      .special-message
        display: flex
        justify-content: center
        align-items: center
        border-radius: 6.7px
        .link-copied
          display: flex
          justify-content: center
          align-items: center
          width: 312px
          height: 51px
          font-size: 14px
          font-weight: 300
          border-radius: 6.7px
          background-color: rgba(113, 191, 113, .2)
          input
            width: 100%
            height: 100%
            font-size: 16px
            font-weight: 300
            background-color: #f4f4f4
            border-radius: 6.7px
            border: 1px solid #d6d6d6
            outline: 0
            padding: 5px
          div
            margin-left: 5px
          .green
            color: #71bf71
        .back-button, div.back-button
          display: flex
          justify-content: center
          align-items: center
          margin-left: 16px
          padding: 12.5px 23.8px
          width: auto
          height: 51px
          text-transform: uppercase
          font-size: 13.8px
          font-weight: 600
          border-radius: 6.7px
          border: 1.6px solid rgba(255, 100, 65, .5)
          cursor: pointer
          &:hover
            color: white
            background-color: rgba(255, 100, 65, .8)
  .special-button
    width: 355px
    height: 59.9px
    margin: 52.2px 0 33.4px
    span
      font-size: 18.7px
      font-weight: bold
.rating-container
  display: flex
  @media screen and ( max-width: 768px )
    justify-content: center
  .rating
    min-width: 110px
  span
    margin-left: 12px
    font-size: 16px
    font-weight: 300
    color: #363636
    b
      font-weight: 800
.we-reward-container
  display: flex
  justify-content: center
  align-items: center
  font-weight: 300
  color: #363636
.we-reward
  max-width: 566px
.section .retailers-intro
  .special-p
    color: #ff6441
    text-transform: uppercase
    font-weight: 300
    font-size: 17px
    letter-spacing: 1.7px
  h3
    line-height: 1.28
    display: flex
    flex-direction: column
    font-size: 33px
    font-weight: 800
  p
    margin-top: 20px
    font-size: 16px
    font-weight: 300
  &__actions
    display: flex
    a
      border-radius: 8px
  &__leran-more
    border: 1px solid #ff6441
    color: #ff6441
    margin-left: 24px
.top-curve
  background-image: url('/static/images/cilp-top-curve.svg')
  background-position: bottom
  background-repeat: no-repeat
.section-testimonials
  padding-top: 100px
.bottom-curve
  background-image: url('/static/images/cilp-bottom-curve.svg')
  background-position: top
  background-repeat: no-repeat
.special-bg
  background-color: #fcfcfc
.section-relax
  margin: 128.2px 0 140px
  h1
    line-height: 0.98
    font-size: 52.5px
    font-weight: 800
  h2
    line-height: 0.98
    font-size: 44px
    font-weight: 600
  .special-button
    margin-top: 39.3px
    min-width: 267.9px
    height: 59.9px
    font-size: 18.7px
    font-weight: 500
.stop-overpaying
  display: flex
  flex-direction: column
  justify-content: center
</style>
